import React, {useEffect, useState} from "react"
import { Container, Row, Col, Form } from "react-bootstrap"

// Styles
import "./BranchDetails/BranchDetails.scss"

// Components
import CustomTextField from "components/CustomTextField"
import CustomDropDown from "components/CustomDropdown"
import CustomCheckbox from "components/CustomCheckbox"
import CustomButton from "components/CustomButton"

const agentTypeOptions = [
    {
        display: 'Please select an option...',
        value: 'Select'
    },
    {
        display: 'Lettings',
        value: 'Lettings'
    },
    {
        display: 'Sales',
        value: 'Sales'
    },
];

const termsAndConditionsOptions = [
    'I agree to the Terms & Conditions and Privacy & Data Protection Policy.'
];

const receiveEventInfo = [
    'I agree to receive newsletters and company updates as per the Privacy Policy.'
];

const AgentDetails = ({user_data, handlechange}) => {
    
    var agent_type_option = user_data?.branch_info?.agent_type ?  agentTypeOptions.filter(item => item.value === user_data?.branch_info?.agent_type) : agentTypeOptions;

    //console.log("agent_type_option", agent_type_option);
    
    return (
        <>
            <Row className="form-fields">
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        name="nego_name"
                        type="text"
                        label="Nego NAME"
                        defaultValue={user_data?.contact_info?.nego_name}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="email"
                        label="EMAIL ADDRESS"
                        readOnly={true}
                        defaultValue={user_data?.email}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomDropDown
                        name="agent_type"
                        label="Agent Type"
                        defaultOption={ agent_type_option[0] }
                        options={ agentTypeOptions }
                        variant="black-caret"
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        label="JOB TITLE"
                        name="job_title"
                        defaultValue={user_data?.contact_info?.job_title}
                    />
                </Col>
                
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="telephone"
                        label="TELEPHONE NUMBER"
                        defaultValue={user_data?.contact_info?.telephone}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="mobile_no"
                        label="MOBILE"
                        defaultValue={user_data?.contact_info?.mobile_no}
                    />
                </Col>                        
                <Col
                    lg="12"
                    xs="12"
                    className="profile_checkbox"
                >
                    <CustomCheckbox
                        isInline={ false }
                        isLabelInline
                        name="terms_and_conditions"
                        options={ termsAndConditionsOptions }
                        checked={user_data?.contact_info?.terms_and_conditions === "Yes" ? true : false}
                        //defaultOption={ termsAndConditionsOptions[ 0 ] }
                    // onChangeCallback={ onChange }
                    />
                </Col>

                <Col
                    lg="12"
                    xs="12"
                    className="profile_checkbox mb-5"
                >
                    <CustomCheckbox
                        isInline={ false }
                        isLabelInline
                        name="receive_event_info"
                        options={ receiveEventInfo }
                        checked={user_data?.contact_info?.receive_event_info === "Yes" ? true : false}
                        //defaultOption={ receiveEventInfo[ 0 ] }
                    // onChangeCallback={ onChange }
                    />
                </Col>
            </Row>
            <div className="button-container">
                <CustomButton
                    variant="green"
                    value="SAVE"
                    type="submit"
                />
                {/* <CustomButton
                    variant="outline"
                    value="CANCEL"
                /> */}
            </div>
        </>
    )
}

export default AgentDetails 
