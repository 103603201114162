import * as React from "react"
import { Container } from "react-bootstrap"

// Styles
import "./ProfileHeader.scss"

const ProfileHeader = () => {
    return (
        <div className="profile-header-wrapper">
            <Container>
                <h2 className="general-heading">
                    My Profile
                </h2>
                <p className="mb-0">
                    Here you can manage your account settings and agent preferences.
                </p>
            </Container>
        </div>
    )
}

export default ProfileHeader 
