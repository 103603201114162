import * as React from "react"

const Divider = ( dividerData ) => {

    const { delimiter = '>' } = dividerData;

    return (
        <span className="divider">
            { delimiter }
        </span>
    )
}

export default Divider
