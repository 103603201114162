export const SEODetails = {
    login: {
        title:"Login",
        description:"Login"
    },
    forgot_pwd: {
        title:"Forgot Password",
        description:"Forgot Password"
    },
    register: {
        title:"Registration",
        description:"Registration"
    },
    dashboard: {
        title:"Dashboard",
        description:"Dashboard"
    },
    my_profile: {
        title:"My Profile",
        description:"My Profile"
    }
}

export const RegisterSteps = {
    step1: {
        title:"Agency Registration",
        steps_text:"Step 1 of 3"
    },
    step2: {
        title:"Agency Details",
        steps_text:"Step 2 of 3"
    },
    step3: {
        title:"Agency Details",
        steps_text:"Step 3 of 3"
    }
}

export const UserAgents_RoleID = "620f554cba2c350f9cef6e65";
