import React, {useState} from "react"
import { Container, Row, Col, Form } from "react-bootstrap"

// Styles
import "./BranchDetails/BranchDetails.scss"

// Components
import CustomTextField from "components/CustomTextField"
import CustomButton from "components/CustomButton"


const ChangePassword = (props) => {
    const [validated, setValidated] = useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            props.setShowerror(true);
            setValidated(true);
            props.setError("Highlighted fields are required.")
            
            setTimeout(() =>{
                props.setError("");
                props.setShowerror(false);
            }, 3000)

        } else{
            event.preventDefault();

            //console.log("password", form.new_password, form.confirm_password)
            if(form.new_password.value === form.confirm_password.value){
                props.setLoading(true);
                const formsdata = event.target
                const params = {}
                const post_data = {}
                Object.keys(formsdata).map(key => (
                    params[formsdata[key].name] = formsdata[key].checked ? "Yes" : formsdata[key].value)
                )
                post_data['password'] =  params.new_password;
                //console.log("post_data ==> ", post_data);
                props.handleAuth(post_data, "New password is updated successfully.");
                
                // reset form
                const form = event.target
                form.reset();

            } else {
                props.setShowerror(true);
                props.setError("New password and confirm password is incorrect.")
            }
            
        }
        
    }

    return (
        <>
        <Form action="/thank-you" method="post" onSubmit={handleSubmit} noValidate validated={validated}>
            <Row className="form-fields">
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="password"
                        name="new_password"
                        label="NEW PASSWORD *"
                        handlechange={props.handlechange}
                        required={true}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="password"
                        name="confirm_password"
                        label="CONFIRM NEW PASSWORD *"
                        handlechange={props.handlechange}
                        required={true}
                    />
                </Col>
            </Row>
            <div className="button-container">
                <CustomButton
                    variant="green"
                    value="Submit"
                    type="submit"
                />
            </div>
        </Form>
        </>
    )
}

export default ChangePassword