import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import "./BranchDetails.scss"

// Components
import CustomTextField from "components/CustomTextField"
import CustomDropDown from "components/CustomDropdown"
import CustomCheckbox from "components/CustomCheckbox"
import CustomButton from "components/CustomButton"

const branch_lettings_list = [
    'Receive Lettings List',
];
const branch_sales_disposal_list = [
    'Receive Sales Disposal List',
];

const BranchDetails = ({user_data, handlechange}) => {
    
    return (
        <>
            <Row className="form-fields">
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        name="agency_name"
                        type="text"
                        label="AGENT NAME"
                        defaultValue={user_data?.branch_info?.agency_name}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        name="branch_name"
                        type="text"
                        label="Branch"
                        defaultValue={user_data?.branch_info?.branch_name}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="branch_address"
                        label="ADDRESS"
                        defaultValue={user_data?.branch_info?.branch_address}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="branch_area"
                        label="TOWN / AREA"
                        defaultValue={user_data?.branch_info?.branch_area}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="branch_city"
                        label="CITY / COUNTRY"
                        defaultValue={user_data?.branch_info?.branch_city}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="branch_postcode"
                        label="POSTCODE"
                        defaultValue={user_data?.branch_info?.branch_postcode}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="branch_phone"
                        label="TELEPHONE NUMBER"
                        defaultValue={user_data?.branch_info?.branch_telephone}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="branch_email"
                        label="EMAIL"
                        defaultValue={user_data?.branch_info?.branch_email}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="6"
                    xs="12"
                >
                    <CustomTextField
                        type="text"
                        name="branch_website"
                        label="WEBSITE URL"
                        defaultValue={user_data?.branch_info?.branch_website}
                        handlechange={handlechange}
                    />
                </Col>
                <Col
                    lg="12"
                    xs="12"
                    className="profile_checkbox"
                >
                    <CustomCheckbox
                        isInline={ false }
                        isLabelInline
                        name="branch_lettings_list"
                        options={ branch_lettings_list }
                        checked={user_data?.branch_info?.is_receive_letting_list === "Yes" ? true : false}
                    // onChangeCallback={ onChange }
                    />
                </Col>
                <Col
                    lg="12"
                    xs="12"
                    className="profile_checkbox mb-5"
                >
                    <CustomCheckbox
                        isInline={ false }
                        isLabelInline
                        name="branch_sales_disposal_list"
                        options={ branch_sales_disposal_list }
                        checked={user_data?.branch_info?.is_receive_disposal_list === "Yes" ? true : false}
                    // onChangeCallback={ onChange }
                    />
                </Col>
            </Row>
            <div className="button-container">
                <CustomButton
                    variant="green"
                    value="SAVE"
                    type="submit"
                />
                {/* <CustomButton
                    variant="outline"
                    value="CANCEL"
                /> */}
            </div>
        </>
    )
}

export default BranchDetails 
