import React, {useState, useEffect} from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { Link, navigate } from "@reach/router"

// Components
import Layout from "../components/layout"
import BreadCrumbs from "components/BreadCrumbs/StaticBreadcrumb"
import ProfileHeader from "layout/MyProfile/ProfileHeader"
import BranchDetails from "layout/MyProfile/BranchDetails"
import AgentDetails from "layout/MyProfile/AgentDetails"
import ChangePassword from "layout/MyProfile/ChangePassword"
import ScrollTabs from "components/ScrollTabs/StaticTabs"

import {isLoggedIn, getUser} from "../services/auth"
import {CustomLinks} from "../components/common/menuUtils"
import {SEODetails} from "../components/common/static_seo"
import { phoneNumberValidation } from "components/common/utils"
import { UpdateUserData } from "services/auth"

const breadCrumbsData = [
    {
        name: 'Dashboard',
        link: '/'+CustomLinks.dashboard+'/'
    },
    {
        name: 'My Profile',
    }
];

const headerTabs = [
    {
        name: 'Agent Details',
        id: '#agentDetails'
    },
    {
        name: 'Branch Details',
        id: '#branchDetails'
    },
    {
        name: 'Change Password',
        id: '#changePassword'
    }
];

const MyProfile = () => {
    const [isAuth, setIsAuth] = useState(isLoggedIn());
    const [get_logged_user, setLoggedUser] = useState(getUser());
    const [selectedTab, setSelectedTab] = useState("Agent Details");
    

    const [loading, setLoading] = useState(false)
    const [showerror, setShowerror] = useState(false)
    const [error, setError] = useState("");
    const [showsuccess, setShowsuccess] = useState(false)
    const [success, setSuccess] = useState("Your details are updated successfully.")

    useEffect(() => {
        if(isAuth){
            navigate('/'+CustomLinks.my_profile+'/')
        } else{
            navigate('/'+CustomLinks.login+'/')
        }
    },[isAuth]);

    const handlechange = event => {
        // remove initial empty spaces
        if(event.target.name === "branch_phone" || event.target.name === "telephone" || event.target.name === "mobile_no"){
          var valid_phone = phoneNumberValidation(event.target.value);
          if(valid_phone){
            event.target.value = valid_phone;
          } else{
            event.target.value = '';
          }
        } else{
          event.target.value = event.target.value.trimStart()
        }
    }

    const handleAuth = async (params, succ_msg) => {
        //console.log("params", params);

        var user_id = get_logged_user?.logged_user_data?.id;
        const regStatus = await UpdateUserData(params, user_id)
  
        //console.log("regStatus", regStatus);
        if (regStatus.status === 201 || regStatus.status === 200) {  
        
          succ_msg && setSuccess(succ_msg);
          setShowsuccess(true); 
  
          setLoading(false);
          window.scrollTo(0,100);

          setTimeout(() =>{
            //setSuccess("");
            setShowsuccess(false);
          }, 3000)
            
        } else {
          setLoading(false);
          setError(regStatus.message)
          setShowerror(true)
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);

        const formsdata = event.target
        const params = {}
        const post_data = {}
        Object.keys(formsdata).map(key => (
            params[formsdata[key].name] = formsdata[key].checked ? "Yes" : formsdata[key].value)
        )

        post_data['nego_name'] = params.nego_name;
        post_data['agency_name'] = params.agency_name;
        post_data['branch_name'] = params.branch_name;

        post_data['branch_info'] = {...get_logged_user?.logged_user_data?.branch_info, "agency_name": params.agency_name, "agent_type": params.agent_type,"branch_address": params.branch_address, "branch_area": params.branch_area, "branch_city": params.branch_city, "branch_email": params.branch_email, "branch_name": params.branch_name, "branch_telephone": params.branch_phone, "branch_postcode": params.branch_postcode, "branch_website": params.branch_website, "is_receive_letting_list": params.branch_lettings_list, "is_receive_disposal_list": params.branch_sales_disposal_list};

        post_data['contact_info'] = {...get_logged_user?.logged_user_data?.contact_info, "nego_name": params.nego_name, "job_title": params.job_title, "telephone": params.telephone, "mobile_no": params.mobile_no, "receive_event_info": params.receive_event_info,  "terms_and_conditions": params.terms_and_conditions};

        //console.log("post_data ==> ", post_data);

        handleAuth(post_data);
    }

    return (
        <>
            <Layout variant={""} Meta_Title={SEODetails.my_profile.title} Meta_Description={SEODetails.my_profile.description}>
                <BreadCrumbs
                    breadCrumbsData={ breadCrumbsData }
                />
                <ProfileHeader />
                <ScrollTabs headerTabs={ headerTabs } defaultTab={selectedTab} SelectedTab={setSelectedTab}>
                    <div className="branch-details-wrapper">
                        <Container>
                            <div className="form-wrapper">
                                {loading && <div className='frm_loader_bg'></div> }

                                {showerror && (
                                    <div className="alert alert-danger mb-5">
                                        {error}
                                    </div>
                                )}

                                {showsuccess && (
                                    <div className="alert alert-success mb-5">
                                        {success}
                                    </div>
                                )}

                                <Form action="/thank-you" method="post" onSubmit={handleSubmit}>
                                    <div className={`prof_form ${selectedTab === "Agent Details" ? "" : "hide"}`}>
                                        <AgentDetails user_data={get_logged_user?.logged_user_data} handlechange={handlechange} />
                                    </div>

                                    <div className={`prof_form ${selectedTab === "Branch Details" ? "" : "hide"}`}>
                                        <BranchDetails user_data={get_logged_user?.logged_user_data} handlechange={handlechange} />
                                    </div>
                                </Form>
                    
                                {selectedTab === "Change Password" && 
                                    <ChangePassword user_data={get_logged_user?.logged_user_data} handleAuth={handleAuth} setLoading={setLoading} setShowerror={setShowerror} setError={setError} /> 
                                }

                            </div>
                        </Container>
                    </div>
                </ScrollTabs>
            </Layout>
        </>
    )
}

export default MyProfile 
