import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap";

// Components
import SectionWrapper from "components/SectionWrapper";

// Styles
import './CustomTextField.scss';

const CustomTextField = ( customTextFieldData ) => {

    const { label, defaultValue = '', onChangeCallBack, variant, type = 'text', name, handlechange, required, readOnly, id } = customTextFieldData;

    const [ input_value, setValue ] = useState(defaultValue ? defaultValue : "");

    const onChange = ( e ) => {
        setValue( e.target.value );
        onChangeCallBack && onChangeCallBack();
    }

    useEffect(() =>{
        setValue(defaultValue);
    },[defaultValue])

    let auto_complete = (type === "text" || type === "email") ? (name === "telephone" ? name : "new_"+name) : "off";

    //console.log("input_value", input_value, defaultValue);

    return (
        <div className={ `form-field custom-text-field-wrapper ${ variant || '' }` }>
            <label>
                { label }
            </label>
            {type === "password" ?
                <input
                    type={ type }
                    className="text-field"
                    //onChange={onChange}
                    onChange={handlechange}
                    name={name}
                    id={id}
                    required={required}
                    //value={input_value}
                    autoComplete={auto_complete}
                />
            :
            <input
                type={ type }
                className="text-field"
                onChange={handlechange}
                name={name}
                required={required}
                autoComplete={auto_complete}
                readOnly={readOnly}
                //value={input_value}
                defaultValue={input_value}
            />
            }
            
        </div>
    )
}

export default CustomTextField
