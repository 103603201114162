import React, {useState} from "react"
import { Container } from "react-bootstrap";
import {Link} from "gatsby"
import ScrollContainer from 'react-indiana-drag-scroll'

// Styles
import './ScrollTabs.scss';

const ScrollTabs = ( { children, headerTabs, defaultTab, SelectedTab } ) => {

    return (
        <div className="scroll-tab-wrapper">
            <TabHeader
                headerTabs={ headerTabs }
                defaultTab={defaultTab}
                SelectedTab={SelectedTab}
            />
            <TabBody>
                { children }
            </TabBody>
        </div>
    )
}

const TabHeader = ( props ) => {

    //console.log("headerTabs", setSelectedTab);
    const [selectedTab, setSelectedTab] = useState(props.defaultTab);
    
    //console.log("selectedTab", selectedTab);

    const select_tab = (val) =>{
        setSelectedTab(val)
        props.SelectedTab(val)
    }

    return (
        <div className="scroll-tab-header">
            <Container>
            <div className="tab_scroll">
                <ScrollContainer hideScrollbars={true}>
                    <div className="scroll-tabs">

                        { props.headerTabs.map( ( headerItem, headerIndex ) => {
                            const isSelected = ( headerItem.name === selectedTab );
                            return (
                                <>
                                    <a href={headerItem.id}
                                        key={ headerIndex }
                                        className={ isSelected ? 'selected' : '' }
                                        onClick={() => select_tab(headerItem.name)}
                                    >
                                        { headerItem.name }
                                    </a>
                                </>
                            )
                        } ) }
                    </div>
                </ScrollContainer>
            </div>
            </Container>
        </div>
    )
}

const TabBody = ( { children } ) => {
    return (
        <div className="scroll-tab-body">
            { children }
        </div>
    )
}

export default ScrollTabs
