import React, { useState } from "react"
import { Form } from "react-bootstrap"

// Styles
import "./CustomCheckbox.scss"

const CustomCheckbox = ( radioButtonData ) => {

    const { name, label, defaultOption, options = [], onChangeCallback, isInline = true, isLabelInline = true, customClass, checked } = radioButtonData;

    const [ selectedOption, setSelectedOption ] = useState({});
    const [ checkedVal, setCheckedVal ] = useState(checked);

    const handleOnChange = ( option ) => {
        setSelectedOption( option );
        onChangeCallback && onChangeCallback( name, option );
    }

    const checkedChange = ( e ) => {
        //console.log("option ==>", e.target.checked)

        if(e.target.checked){
            setCheckedVal(true)
        } else{
            setCheckedVal(false)
        }
    }


    return (
        <div
            className={ `form-field custom-checkbox-wrapper ${ isLabelInline ? 'custom-checkbox-label-inline' : '' } ${ customClass || '' }` }
        >
            { label && (
                <label className="custom-label" >
                    { label }
                </label>
            ) }
            <Form.Group>
                <div
                    className={ isInline ? 'inline-group' : '' }
                >
                    { options.map( ( optionItem, optionsIndex ) => {
                        return (
                            <Form.Check
                                className={ options.length < 2 ? "me-0" : "" }
                                key={ optionsIndex }
                                inline={ isInline }
                                type="checkbox"
                                label={ optionItem }
                                name={ name }
                                id={ `inline-${ name }-${ optionsIndex }` }
                                onChange={ (e) => {handleOnChange( optionItem ); checkedChange(e);} }
                                value=""
                                checked={checkedVal}
                            />
                        )
                    } ) }
                </div>
            </Form.Group>
        </div>
    )
}

export default CustomCheckbox
